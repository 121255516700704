// src/authProvider.js

console.log("process.env.REACT_APP_UMBRA_BASE_URL", process.env);

const authProvider = {
	login: ({ username, password }) => {
		const request = new Request(
			`${process.env.REACT_APP_UMBRA_BASE_URL}/api/v2/auth/login`,
			{
				method: "POST",
				body: JSON.stringify({ username, password }),
				headers: new Headers({ "Content-Type": "application/json" }),
			},
		);

		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((data) => {
				if (data.status === 200) {
					// Store the JWT access and refresh tokens in localStorage
					localStorage.setItem("jwt_access_token", data.jwt_access_token);
					localStorage.setItem("jwt_refresh_token", data.jwt_refresh_token);
					localStorage.setItem("user_id", data.user_id);
					return Promise.resolve();
				} else {
					throw new Error(data.message);
				}
			});
	},

	logout: () => {
		// Remove both tokens from localStorage
		localStorage.removeItem("jwt_access_token");
		localStorage.removeItem("jwt_refresh_token");
		localStorage.removeItem("user_id");
		return Promise.resolve();
	},

	checkAuth: () => {
		const accessToken = localStorage.getItem("jwt_access_token");
		const refreshToken = localStorage.getItem("jwt_refresh_token");

		console.log("isTokenExpired(accessToken)", isTokenExpired(accessToken));
		console.log(
			"getTokenRemainingTime(accessToken)",
			getTokenRemainingTime(accessToken),
		);

		if (!accessToken) {
			return Promise.reject();
		}

		// Check if the access token is expired
		const isExpired = isTokenExpired(accessToken);
		if (!isExpired) {
			return Promise.resolve();
		}

		// If the access token is expired, try to refresh it asynchronously
		if (refreshToken) {
			return refreshAccessToken(refreshToken)
				.then((data) => {
					localStorage.setItem("jwt_access_token", data.jwt_access_token);
					localStorage.setItem("jwt_refresh_token", data.jwt_refresh_token);
					localStorage.setItem("user_id", data.user_id);

					window.location.reload();

					return Promise.resolve();
				})
				.catch((error) => {
					// If refresh fails, logout the user
					authProvider.logout();
					return Promise.reject();
				});
		} else {
			return Promise.reject();
		}
	},

	checkError: (error) => {
		const status = error.status;
		if (status === 401 || status === 403) {
			// If unauthorized, remove both tokens
			authProvider.logout();
			return Promise.reject();
		}
		return Promise.resolve();
	},

	getIdentity: () => {
		try {
			const user_id = localStorage.getItem("user_id");
			return Promise.resolve({
				id: user_id,
				fullName: user_id, // Adjust this if you have more user data
			});
		} catch (error) {
			return Promise.reject(error);
		}
	},

	getPermissions: () => Promise.resolve(),
};

// Utility function to get expiry time of token
function getTokenExpiry(token) {
	if (token === null) {
		return 0;
	}
	const decodedToken = JSON.parse(atob(token.split(".")[1]));
	return decodedToken.exp;
}

// Utility function to get remianing time of token
function getTokenRemainingTime(token) {
	if (token === null) {
		return 0;
	}
	const decodedToken = JSON.parse(atob(token.split(".")[1]));
	const currentTime = Math.floor(Date.now() / 1000);
	return decodedToken.exp - currentTime;
}

// Utility function to check if the JWT is expired
function isTokenExpired(token) {
	if (token === null) {
		return true;
	}
	const decodedToken = JSON.parse(atob(token.split(".")[1]));
	const currentTime = Math.floor(Date.now() / 1000);
	return decodedToken.exp < currentTime;
}

// Utility function to refresh the access token
async function refreshAccessToken(jwt_refresh_token) {
	console.log("refreshAccessToken");

	const request = new Request(
		`${process.env.REACT_APP_UMBRA_BASE_URL}/api/v2/auth/token-refresh`,
		{
			method: "POST",
			body: JSON.stringify({ jwt_refresh_token: jwt_refresh_token }),
			headers: new Headers({ "Content-Type": "application/json" }),
		},
	);

	const response = await fetch(request);
	if (response.status < 200 || response.status >= 300) {
		throw new Error("Failed to refresh token");
	}
	const data = await response.json();
	return data;
}

export default authProvider;
