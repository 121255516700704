import React from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route, BrowserRouter } from "react-router-dom";
import authProvider from "./authProvider";
import dataProvider from "./dataProvider";

import { CustomLayout } from "./ui/customLayout";
import customTheme from "./ui/customTheme";

import Dashboard from "./Dashboard";
import { LocalesProvider } from "./contexts/LocalesContext";
import {
	ForumTopicList,
	ForumTopicEdit,
	ForumTopicCreate,
	ForumTopicShow,
} from "./features/ForumTopics";
import {
	ForumReplyList,
	ForumReplyEdit,
	ForumReplyCreate,
	ForumReplyShow,
} from "./features/ForumReplies";
import {
	ContentTagList,
	ContentTagEdit,
	ContentTagCreate,
	ContentTagShow,
} from "./features/ContentTags";
import {
	ReminderNotificationList,
	ReminderNotificationEdit,
	ReminderNotificationCreate,
	ReminderNotificationShow,
} from "./features/ReminderNotifications";
import {
	DiagnoseList,
	DiagnoseEdit,
	DiagnoseCreate,
	DiagnoseShow,
} from "./features/Diagnoses";
import {
	ContentCategoryList,
	ContentCategoryEdit,
	ContentCategoryCreate,
	ContentCategoryShow,
} from "./features/ContentCategories";
import {
	ReactionList,
	ReactionEdit,
	ReactionCreate,
	ReactionShow,
} from "./features/Reactions";
import {
	UserList,
	UserEdit,
	UserCreate,
	UserShow,
} from "./features/Users/Index";
import ApiDocs from "./features/ApiDocs";
import {
	NotificationBarItemList,
	NotificationBarItemEdit,
	NotificationBarItemCreate,
	NotificationBarItemShow,
} from "./features/NotificationBarItems";
import MyAccount from "./features/MyAccount";
import { TaskList } from "./features/Tasks";
import {
	AuthorCreate,
	AuthorEdit,
	AuthorList,
	AuthorShow,
} from "./features/Authors";
import {
	ExpertQuestionCreate,
	ExpertQuestionEdit,
	ExpertQuestionList,
	ExpertQuestionShow,
} from "./features/ExpertQuestions";
import {
	EventRecordList,
	EventRecordCreate,
	EventRecordShow,
} from "./features/EventRecords";
import { TodoList, TodoEdit, TodoCreate, TodoShow } from "./features/Todos";

const App = () => {
	return (
		<BrowserRouter>
			<LocalesProvider>
				<Admin
					dataProvider={dataProvider}
					authProvider={authProvider}
					layout={CustomLayout}
					theme={customTheme}
					dashboard={Dashboard}
				>
					<Resource
						name="contentCategories"
						list={ContentCategoryList}
						edit={ContentCategoryEdit}
						create={ContentCategoryCreate}
						show={ContentCategoryShow}
					/>
					<Resource
						name="contentTags"
						list={ContentTagList}
						edit={ContentTagEdit}
						create={ContentTagCreate}
						show={ContentTagShow}
					/>
					<Resource
						name="mindhub/authors"
						list={AuthorList}
						edit={AuthorEdit}
						create={AuthorCreate}
						show={AuthorShow}
					/>
					<Resource
						name="diagnoses"
						list={DiagnoseList}
						edit={DiagnoseEdit}
						create={DiagnoseCreate}
						show={DiagnoseShow}
					/>
					<Resource
						name="forum/topics"
						list={ForumTopicList}
						edit={ForumTopicEdit}
						create={ForumTopicCreate}
						show={ForumTopicShow}
					/>
					<Resource
						name="forum/replies"
						list={ForumReplyList}
						edit={ForumReplyEdit}
						create={ForumReplyCreate}
						show={ForumReplyShow}
					/>
					<Resource
						name="reactions"
						list={ReactionList}
						edit={ReactionEdit}
						create={ReactionCreate}
						show={ReactionShow}
					/>
					<Resource
						name="users"
						list={UserList}
						edit={UserEdit}
						create={UserCreate}
						show={UserShow}
					/>
					<Resource
						name="notificationBarItems"
						list={NotificationBarItemList}
						edit={NotificationBarItemEdit}
						create={NotificationBarItemCreate}
						show={NotificationBarItemShow}
					/>

					<Resource
						name="tools/ask-expert/expertQuestions"
						list={ExpertQuestionList}
						edit={ExpertQuestionEdit}
						create={ExpertQuestionCreate}
						show={ExpertQuestionShow}
					/>

					<Resource
						name="reminderNotifications"
						list={ReminderNotificationList}
						edit={ReminderNotificationEdit}
						create={ReminderNotificationCreate}
						show={ReminderNotificationShow}
					/>

					<Resource name="tasks" list={TaskList} />

					<Resource
						name="eventRecords"
						list={EventRecordList}
						create={EventRecordCreate}
						show={EventRecordShow}
					/>

					<Resource
						name="tools/todos"
						list={TodoList}
						edit={TodoEdit}
						create={TodoCreate}
						show={TodoShow}
					/>

					<CustomRoutes>
						<Route path="/my-account" element={<MyAccount />} />
						<Route path="/api-docs" element={<ApiDocs />} />
					</CustomRoutes>
				</Admin>
			</LocalesProvider>
		</BrowserRouter>
	);
};

export default App;
