import React, { useState, useRef } from "react";
import { Button, Card, CardContent, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { useDataProvider } from "react-admin";
import { useLocales } from "../contexts/LocalesContext";
import customDataProvider from "../dataProvider";

const VerifyForumReplyTranslations = ({ resource }) => {
    const dataProvider = useDataProvider();

    const [start, setStart] = useState(0);
    const [totalReplies, setTotalReplies] = useState(0);
    const [repliesChecked, setRepliesChecked] = useState(0);
    const [repliesMissingTranslations, setRepliesMissingTranslations] = useState(0);
    const [isChecking, setIsChecking] = useState(false);
    const [fixTranslations, setFixTranslations] = useState(false); // State for the checkbox
    const isStoppedRef = useRef(false); // useRef to hold the stop state

    const { locales } = useLocales();

    const fetchAndCheckReplies = async (currentStart = start) => {
        const pageSize = 100;
        setIsChecking(true);

        try {
            if (currentStart === 0) {
                setTotalReplies(0);
                setRepliesChecked(0);
                setRepliesMissingTranslations(0);
                // Initial fetch to get the total number of replies
                const initialResponse = await dataProvider.getList(resource, {
                    pagination: { page: 1, perPage: pageSize },
                    sort: { field: "id", order: "ASC" },
                    filter: {},
                });

                const total = parseInt(initialResponse.total, 10);
                setTotalReplies(total);
            }

            if (isStoppedRef.current) {
                return; // Use ref to check if the process is stopped
            }

            const response = await dataProvider.getList(resource, {
                pagination: { page: currentStart / pageSize + 1, perPage: pageSize },
                sort: { field: "id", order: "ASC" },
                filter: {},
            });

            const forumReplies = response.data;

            if (forumReplies.length === 0) {
                console.log("No replies found");
                stopChecking();
                return;
            }

            for (const reply of forumReplies) {
                const replyLanguage = reply.language;

                // skip check if reply language is empty
                if (!replyLanguage) {
                    console.log("Reply language is empty:", reply.id);
                } else {
                    let replyLocale = locales.find((locale) => locale.language === replyLanguage);

                    if (!replyLocale) {
                        console.log(`Reply language '${replyLanguage}' not available in locales. Reply id ${reply.id}`);
                        replyLocale = { code: "" };
                    }

                    const translations = reply.translatedContent || {};
                    let translationIsMissing = false;
                    let translationIsMissingLocales = [];

                    // Check if translations exist for all locales except the reply's own language
                    locales.forEach((locale) => {
                        const translation = translations[locale.code];
                        if (!translation && locale.code !== replyLocale.code) {
                            translationIsMissingLocales.push(locale.code);
                            translationIsMissing = true;
                        }
                    });

                    if (translationIsMissing) {
                        setRepliesMissingTranslations((prev) => prev + 1);
                        console.log(`Reply ${reply.id} is missing translations for [${translationIsMissingLocales.join(",")}]`);

                        if (fixTranslations) {
                            console.log(`apiUrl`, customDataProvider.getApiUrl());

                            fetch(`${customDataProvider.getApiUrl()}/${resource}/${reply.id}/fixTranslations`, {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
                                },
                            });

                            return;
                        }
                        stopChecking();
                    }
                }
                setRepliesChecked((prev) => prev + 1);
            }

            const newStart = currentStart + pageSize;
            setStart(newStart);

            if (totalReplies === 0 || (newStart < totalReplies && !isStoppedRef.current)) {
                // Recursive call to fetch the next batch, passing the new start value
                await fetchAndCheckReplies(newStart);
            } else {
                setIsChecking(false);
            }
        } catch (error) {
            console.error("Error fetching forum replies:", error);
            setIsChecking(false);
        }
    };

    const startChecking = () => {
        setRepliesMissingTranslations(0);
        isStoppedRef.current = false; // Reset the stop state
        setStart(0); // Reset start to 0
        fetchAndCheckReplies(0); // Start with 0
    };

    const stopChecking = () => {
        console.log("stopChecking");
        isStoppedRef.current = true; // Set the stop state
        setIsChecking(false);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Verify Forum Reply Translations</Typography>
                <Typography>Total number of replies: {totalReplies}</Typography>
                <Typography>Replies checked: {repliesChecked}</Typography>
                <Typography>Replies missing translations: {repliesMissingTranslations}</Typography>
                <FormControlLabel
                    control={<Checkbox checked={fixTranslations} onChange={(e) => setFixTranslations(e.target.checked)} />}
                    label="Fix translations when found"
                />
                <Button variant="contained" color="primary" onClick={startChecking} disabled={isChecking}>
                    {isChecking ? "Checking..." : "Start"}
                </Button>
                <Button variant="contained" color="secondary" onClick={stopChecking} disabled={!isChecking}>
                    Stop
                </Button>
            </CardContent>
        </Card>
    );
};

export default VerifyForumReplyTranslations;
