import React from "react";
import {
    TranslatableInputs,
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    Show,
    SimpleShowLayout,
    TranslatableFields,
    BulkDeleteButton,
} from "react-admin";

import LocaleTabs from "../components/LocaleTabs";
import { withDefaultLocale } from "../components/withDefaultLocale";
import { useLocales } from "../contexts/LocalesContext";
import { useEscapeToList } from "../hooks/useEscapeToList";

export const DiagnoseCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/diagnoses");

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                </TranslatableInputs>
                <TextInput source="userId" />
                <TextInput source="taxonomyType" />
            </SimpleForm>
        </Create>
    );
});

export const DiagnoseEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/diagnoses");

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                </SimpleShowLayout>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                </TranslatableInputs>
                <TextInput source="userId" />
                <TextInput source="taxonomyType" />
            </SimpleForm>
        </Edit>
    );
});

export const DiagnoseShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/diagnoses");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TranslatableFields locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextField source="title" emptyText="" />
                </TranslatableFields>
                <TextField source="userId" />
                <TextField source="taxonomyType" />
            </SimpleShowLayout>
        </Show>
    );
});

export const DiagnoseList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source={`title.${defaultLocale}`} label={`Title (${defaultLocale})`} />
                <TextField source="userId" />
                <TextField source="taxonomyType" />
                <EditButton />
            </Datagrid>
        </List>
    );
});
