// src/MyAccount.js

import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";

const MyAccount = () => {
	const userId = localStorage.getItem("user_id");
	const accessToken = localStorage.getItem("jwt_access_token");
	const refreshToken = localStorage.getItem("jwt_refresh_token");

	useEffect(() => {
		if (
			accessToken === undefined ||
			accessToken === null ||
			refreshToken === ""
		) {
			window.location.href = "/login";
			return;
		}
	});

	return (
		<Card>
			<CardContent>
				<Typography variant="h5" component="h2">
					My Account (env: {process.env.REACT_APP_ENV})
				</Typography>
				<Typography color="textSecondary" gutterBottom>
					User ID: {userId}
				</Typography>
				<Typography color="textSecondary" gutterBottom>
					Access Token: {accessToken}
				</Typography>
				<Typography color="textSecondary" gutterBottom>
					Refresh Token: {refreshToken}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default MyAccount;
