// in src/CustomLayout.js
import { Layout } from "react-admin";
import { CustomMenu } from "./customMenu";
import { CustomAppBar } from "./customAppBar";

export const CustomLayout = ({ children }) => (
    <Layout menu={CustomMenu} appBar={CustomAppBar}>
        {children}
    </Layout>
);
