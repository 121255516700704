import React from "react";
import {
    TranslatableInputs,
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    Show,
    SimpleShowLayout,
    NumberInput,
    ImageField,
    Labeled,
    BooleanInput,
    BulkDeleteButton,
} from "react-admin";
import ImageSelector from "../components/ImageSelector";
import LocaleTabs from "../components/LocaleTabs";
import { withDefaultLocale } from "../components/withDefaultLocale";
import { useLocales } from "../contexts/LocalesContext";
import { useEscapeToList } from "../hooks/useEscapeToList";

export const AuthorCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/mindhub/authors");

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <TextInput source="name" />
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                    <TextInput source="bio" />
                </TranslatableInputs>

                <Labeled>
                    <ImageSelector source="avatarImageUrl" label="Avatar" />
                </Labeled>
                <Labeled>
                    <ImageSelector source="headerImageUrl" label="Header" />
                </Labeled>
                <BooleanInput source="isExpert" />
                <NumberInput source="sortOrder" label="Sort order" />
            </SimpleForm>
        </Create>
    );
});

export const AuthorEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/mindhub/authorsmindhub/authors");

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="phpId" />
                </SimpleShowLayout>
                <TextInput source="name" />
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                    <TextInput source="bio" />
                    <Labeled>
                        <TextField source="bioMarkdown" />
                    </Labeled>
                </TranslatableInputs>

                <Labeled>
                    <ImageSelector source="avatarImageUrl" label="Avatar" />
                </Labeled>
                <Labeled>
                    <ImageSelector source="headerImageUrl" label="Header" />
                </Labeled>
                <BooleanInput source="isExpert" />
                <NumberInput source="sortOrder" label="Sort order" />
            </SimpleForm>
        </Edit>
    );
});

export const AuthorShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/mindhub/authors");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source="name" />
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <Labeled>
                        <TextField source="title" />
                    </Labeled>
                    <Labeled>
                        <TextField source="bio" />
                    </Labeled>
                    <Labeled>
                        <TextField source="bioMarkdown" />
                    </Labeled>
                </TranslatableInputs>
                <ImageField source="avatarImageUrl" title="title" className="list-image" />
                <ImageField source="headerImageUrl" title="title" className="list-image" />
                <TextField source="isExpert" />
                <TextField source="sortOrder" />
            </SimpleShowLayout>
        </Show>
    );
});

export const AuthorList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source="name" />

                <TextField source={`title.${defaultLocale}`} label={`Title (${defaultLocale})`} />
                <TextField source={`bio.${defaultLocale}`} label={`Bio (${defaultLocale})`} />
                {/* <TextField source={`bioMarkdown.${defaultLocale}`} label={`Bio Markdown (${defaultLocale})`} /> */}

                <ImageField source="avatarImageUrl" title="title" className="list-image" />
                <ImageField source="headerImageUrl" title="title" className="list-image" />
                <TextField source="isExpert" />
                <TextField source="sortOrder" />
                <EditButton />
            </Datagrid>
        </List>
    );
});
