import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, CircularProgress } from "@mui/material";
import { useNotify } from "react-admin";
import { List, Datagrid, TextField } from "react-admin";
import customDataProvider from "../dataProvider";
import CustomDateField from "../components/CustomDateField";

const Tasks = () => {
    const [taskStats, setTaskStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const notify = useNotify();

    useEffect(() => {
        const fetchTaskStats = async () => {
            try {
                const apiUrl = customDataProvider.getApiUrl();

                const response = await fetch(`${apiUrl}/tasks/stats`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTaskStats(data);
                } else {
                    notify("Failed to fetch task stats", { type: "error" });
                }
            } catch (error) {
                notify("Error fetching task stats", { type: "error" });
            } finally {
                setLoading(false);
            }
        };

        fetchTaskStats();
    }, [notify]);

    if (loading) return <CircularProgress />;

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Task Statistics
                </Typography>
                {taskStats && (
                    <>
                        <Typography color="textSecondary" gutterBottom>
                            Queue ID: {taskStats.queueId}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom>
                            Total Task Count: {taskStats.taskCount}
                        </Typography>
                        <Typography variant="h6">Task Actions:</Typography>
                        {Object.entries(taskStats.taskActions).map(([action, count]) => (
                            <Typography key={action} color="textSecondary">
                                {action}: {count}
                            </Typography>
                        ))}
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export const TaskList = (props) => {
    return (
        <>
            <Tasks />
            <List {...props}>
                <Datagrid>
                    <TextField source="name" />
                    <CustomDateField source="scheduleTime" />
                    <TextField source="payload.userId" />
                    <TextField source="payload.action" />
                </Datagrid>
            </List>
        </>
    );
};

export default Tasks;
