import { useState, useEffect } from "react";
import { TextField, Labeled, useNotify } from "react-admin"; // Import useNotify
import { useForm, useFormContext } from "react-hook-form";
import { ReactMediaLibrary } from "react-media-library";
import { Button } from "@mui/material";
import customDataProvider from "../dataProvider";

const ImageSelector = ({ source }) => {
    const formContext = useFormContext();
    const form = useForm();
    const notify = useNotify(); // Initialize the useNotify hook

    const [isLibraryOpen, setIsLibraryOpen] = useState(false);
    const [fileLibraryList, setFileLibraryList] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const apiUrl = customDataProvider.getApiUrl();

                const response = await fetch(`${apiUrl}/media/files`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
                    },
                });

                if (response.ok) {
                    const files = await response.json();
                    const transformedFiles = files.map((file) => ({
                        _id: file.name,
                        title: file.baseName,
                        size: parseInt(file.size, 10),
                        fileName: file.baseName,
                        type: file.contentType,
                        createdAt: new Date(file.timeCreated),
                        thumbnailUrl: file.url,
                    }));
                    setFileLibraryList(transformedFiles);
                } else {
                    notify("Failed to fetch files", { type: "error" });
                }
            } catch (error) {
                notify("Error fetching files", { type: "error" });
            }
        };

        fetchFiles();

        let current_source = typeof source === "object" && source.source ? source.source : source;
        let current_url = formContext.getValues()[current_source];
        if (current_url) {
            setSelectedImage(current_url);
        }
    }, []);

    const handleMediaSelect = (selectedFiles) => {
        const selectedFile = selectedFiles[0];
        setSelectedImage(selectedFile.thumbnailUrl);

        let current_source = typeof source === "object" && source.source ? source.source : source;
        formContext.setValue(current_source, selectedFile.thumbnailUrl, { shouldDirty: true });
        form.trigger();

        setIsLibraryOpen(false);
    };

    const openLibrary = () => {
        setIsLibraryOpen(true);
    };

    const closeLibrary = () => {
        setIsLibraryOpen(false);
    };

    const clearUrl = () => {
        setSelectedImage(null);

        let current_source = typeof source === "object" && source.source ? source.source : source;
        formContext.setValue(current_source, null, { shouldDirty: true });
        form.trigger();
    };

    const deleteCallback = async (files) => {
        const file = files[0];

        if (!window.confirm("Are you sure you want to delete this file? This action cannot be undone.")) {
            return;
        }

        const fileName = encodeURIComponent(file._id);

        try {
            const apiUrl = customDataProvider.getApiUrl();

            const response = await fetch(`${apiUrl}/media/files/${fileName}`, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
                },
            });

            if (response.ok) {
                const index = fileLibraryList.findIndex((f) => f._id === file._id);
                if (index > -1) {
                    const newFileLibraryList = [...fileLibraryList];
                    newFileLibraryList.splice(index, 1);
                    setFileLibraryList(newFileLibraryList);
                }
            } else {
                notify("File delete failed", { type: "error" });
            }
        } catch (error) {
            notify("File delete error", { type: "error" });
        }
    };

    const uploadCallback = async (file) => {
        const formData = new FormData();
        formData.append("file", file);

        try {
            const apiUrl = customDataProvider.getApiUrl();

            const response = await fetch(`${apiUrl}/media/upload`, {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                file = data.fileDetails;
                const newFile = {
                    _id: file.name,
                    title: file.baseName,
                    size: parseInt(file.size, 10),
                    fileName: file.baseName,
                    type: file.contentType,
                    createdAt: new Date(file.timeCreated),
                    thumbnailUrl: file.url,
                };
                setFileLibraryList([...fileLibraryList, newFile]);
                return newFile;
            } else {
                const errorJson = await response.json();
                notify(`Upload failed. Error: ${errorJson.error}`, { type: "error" });
                return;
            }
        } catch (error) {
            notify("Upload error", { type: "error" });
            return;
        }
    };

    return (
        <div>
            <Labeled>
                <TextField source="Image" />
            </Labeled>
            {selectedImage && <img src={selectedImage} alt="Selected" style={{ maxWidth: "100px" }} />}
            <div style={{ display: "flex", gap: "10px" }}>
                <Button variant="outlined" onClick={openLibrary} size="small">
                    Select
                </Button>
                <Button variant="outlined" onClick={clearUrl} size="small">
                    Remove
                </Button>
            </div>
            <ReactMediaLibrary
                isOpen={isLibraryOpen}
                onClose={closeLibrary}
                fileLibraryList={fileLibraryList}
                filesSelectCallback={handleMediaSelect}
                fileUploadCallback={uploadCallback}
                filesDeleteCallback={deleteCallback}
                multiSelect={false}
                modalTitle="Select an Image"
            />
        </div>
    );
};

export default ImageSelector;
