import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Tooltip } from "@mui/material";
import { useRecordContext, useGetList } from "react-admin";
import { formatDistanceToNow, format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";

// New component for Event Records List
const EventRecordsList = () => {
	const record = useRecordContext();
	const navigate = useNavigate();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 20,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "createdAt", sort: "desc" },
	]);

	const { data, total, isLoading } = useGetList("eventRecords", {
		pagination: {
			page: paginationModel.page + 1,
			perPage: paginationModel.pageSize,
		},
		sort: {
			field: sortModel[0]?.field || "createdAt",
			order: sortModel[0]?.sort?.toUpperCase() || "DESC",
		},
		filter: { userId: record.id },
	});

	const columns = [
		{
			field: "createdAt",
			headerName: "Created At",
			width: 200,
			renderCell: (params) => (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<div style={{ padding: "10px 0" }}>
						<span
							style={{
								margin: 0,
								whiteSpace: "nowrap",
								display: "block",
								lineHeight: "1.2em",
							}}
						>
							{formatDistanceToNow(new Date(params.value), { addSuffix: true })}
						</span>
						<span
							style={{
								fontSize: "0.8em",
								opacity: 0.5,
								margin: 0,
								whiteSpace: "nowrap",
								display: "block",
								lineHeight: "1.2em",
							}}
						>
							{format(params.value, "yyyy-MM-dd HH:mm:ss")}
						</span>
					</div>
				</div>
			),
		},
		{ field: "eventType", headerName: "Event Type", width: 150 },
		{ field: "eventName", headerName: "Event Name", width: 150 },
		{
			field: "value",
			headerName: "Value",
			width: 200,
			renderCell: (params) => (
				<Tooltip title={<pre>{params.value}</pre>} placement="left">
					<span>
						{params.value.length > 50
							? `${params.value.substring(0, 50)}...`
							: params.value}
					</span>
				</Tooltip>
			),
		},
		{
			field: "metadata",
			headerName: "Metadata",
			width: 200,
			renderCell: (params) => {
				const content =
					typeof params.value === "object"
						? JSON.stringify(params.value)
						: params.value;
				return (
					<Tooltip title={<pre>{content}</pre>} placement="left">
						<span>
							{content.length > 50 ? `${content.substring(0, 50)}...` : content}
						</span>
					</Tooltip>
				);
			},
		},
	];

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent>
				<Typography variant="h6" gutterBottom>
					Event Records for User
				</Typography>
				{total === 0 ? (
					<Typography>- no records -</Typography>
				) : (
					<div style={{ height: 400, width: "100%" }}>
						<DataGrid
							rows={data || []}
							columns={columns}
							paginationModel={paginationModel}
							onPaginationModelChange={setPaginationModel}
							sortModel={sortModel}
							onSortModelChange={setSortModel}
							rowCount={total}
							loading={isLoading}
							paginationMode="server"
							sortingMode="server"
							disableRowSelectionOnClick
							onRowClick={(params) =>
								navigate(`/eventRecords/${params.id}/show`)
							}
							sx={{ cursor: "pointer" }}
							keepNonExistentRowsSelected
						/>
					</div>
				)}
			</CardContent>
		</Card>
	);
};

export default EventRecordsList;
