import { Labeled } from "react-admin";
import { styled } from "@mui/material/styles";

// Create a styled version of Labeled with right padding
const PaddedLabeled = styled(Labeled)(({ theme }) => ({
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

export default PaddedLabeled;
