// src/MyAccount.js

import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import dataProvider from "../dataProvider";
import { RedocStandalone } from "redoc";

const MyAccount = () => {
	const userId = localStorage.getItem("user_id");
	const accessToken = localStorage.getItem("jwt_access_token");
	const refreshToken = localStorage.getItem("jwt_refresh_token");

	const [docs, setDocs] = useState(undefined);
	const [loading, setLoading] = useState(false);

	const fetchDocs = async () => {
		try {
			setLoading(true);

			const result = await fetch(`${dataProvider.getApiUrl()}/docs/api.json`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
				},
			});

			if (result.status !== 200) {
				if (result.status === 401) {
					window.location.href = "/login";
					return;
				}

				console.log(
					`Failed to fetch api docs, status ${result.status}`,
					await result.json(),
				);
				return;
			}

			setDocs(await result.json());
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!docs && !loading) {
			fetchDocs();
		}
	}, [accessToken]);

	if (!accessToken) {
		return (
			<Card>
				<CardContent>
					<Typography variant="h5" component="h2">
						Login to view docs
					</Typography>
				</CardContent>
			</Card>
		);
	}

	if (loading) {
		return (
			<Card>
				<CardContent>
					<Typography variant="h5" component="h2">
						Loading docs
					</Typography>
				</CardContent>
			</Card>
		);
	}

	if (docs) {
		return <RedocStandalone spec={docs} />;
	}

	return null;
};

export default MyAccount;
