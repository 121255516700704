import React, { useState } from "react";
import { TextField as MuiTextField } from "@mui/material";
import { useForm, useFormContext } from "react-hook-form";

// Custom input component for JSON array
const JsonArrayInput = ({ source }) => {
	const formContext = useFormContext();
	const form = useForm();
	const { getValues, setValue } = useFormContext();
	const initialValue = getValues(source) || [];
	const [inputValue, setInputValue] = useState(
		Array.isArray(initialValue) ? initialValue.join(", ") : "",
	);

	const handleChange = (event) => {
		const newValue = event.target.value;
		setInputValue(newValue);
		const arrayValue = newValue.split(",").map((item) => item.trim());
		setValue(source, arrayValue);

		const current_source =
			typeof source === "object" && source.source ? source.source : source;
		formContext.setValue(current_source, arrayValue, { shouldDirty: true });
		form.trigger();
	};

	return (
		<div style={{ marginBottom: "20px" }}>
			<MuiTextField
				label={source}
				value={inputValue}
				onChange={handleChange}
				variant="outlined"
			/>
		</div>
	);
};

export default JsonArrayInput;
