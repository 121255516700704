// src/ui/customMenu.js

import React from "react";
import { Menu } from "react-admin";
import ListIcon from "@mui/icons-material/List";
import AccountIcon from "@mui/icons-material/Face";
import GroupIcon from "@mui/icons-material/Group";
import DocumentScanner from "@mui/icons-material/DocumentScanner";
import styled from "@emotion/styled";
import { environmentBaseColors } from "../theme/colors";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ListAltIcon from "@mui/icons-material/ListAlt";

// icons: https://mui.com/material-ui/material-icons

const StyledMenuItem = styled(Menu.Item)(({ theme }) => {
	const selectedEnvironment =
		localStorage.getItem("selectedEnvironment") || "default";
	const environmentColor = environmentBaseColors[selectedEnvironment];

	return {
		"&.RaMenuItemLink-active": {
			backgroundColor: environmentColor,
			fontWeight: "bold",
			borderLeft: `4px solid ${environmentColor}`,
			color: "white",
		},
	};
});

export const CustomMenu = () => (
	<Menu>
		<StyledMenuItem to="/" primaryText="Dashboard" />
		<StyledMenuItem
			to="/contentCategories"
			primaryText="Categories"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/contentTags"
			primaryText="Tags"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/diagnoses"
			primaryText="Diagnoses"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/forum/topics"
			primaryText="Forum topics"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/forum/replies"
			primaryText="Forum replies"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/reactions"
			primaryText="Reactions"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem to="/users" primaryText="Users" leftIcon={<GroupIcon />} />
		<StyledMenuItem
			to="/notificationBarItems"
			primaryText="Notification Bar Items"
			leftIcon={<GroupIcon />}
		/>
		<StyledMenuItem
			to="/mindhub/authors"
			primaryText="Authors"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/tools/ask-expert/expertQuestions"
			primaryText="Expert Questions"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/reminderNotifications"
			primaryText="Reminder Notifications"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/tasks"
			primaryText="Tasks"
			leftIcon={<AssignmentIcon />}
		/>
		<StyledMenuItem
			to="/tools/todos"
			primaryText="Todos"
			leftIcon={<ListAltIcon />}
		/>
		<StyledMenuItem
			to="/eventRecords"
			primaryText="Event Records"
			leftIcon={<ListIcon />}
		/>
		<StyledMenuItem
			to="/my-account"
			primaryText="My Account"
			leftIcon={<AccountIcon />}
		/>
		<StyledMenuItem
			to="/api-docs"
			primaryText="API Docs"
			leftIcon={<DocumentScanner />}
		/>
	</Menu>
);
