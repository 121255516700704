import React, { useState, useEffect } from "react";
import { List, Datagrid, TextField, Show, SimpleShowLayout } from "react-admin";
import {
	TextField as MuiTextField,
	Button as MuiButton,
	Card,
	CardContent,
	CircularProgress,
	Typography,
} from "@mui/material";
import { useEscapeToList } from "../../hooks/useEscapeToList";
import { useNotify, useRecordContext } from "react-admin";
import { useShowController } from "react-admin";
import customDataProvider from "../../dataProvider";
import Grid from "@mui/material/Grid";
import { Alert } from "@mui/material";
import { useRefresh } from "react-admin";
import CustomDateField from "../../components/CustomDateField";
import PaddedLabeled from "../../components/PaddedLabeled";
import UserTitle from "./UserTitle";
import EventRecordsList from "./EventRecordsList";
import TodoItemsList from "./TodoItemsList";

export const UserShow = (props) => {
	useEscapeToList("/users");

	const [smsMessage, setSmsMessage] = useState("");
	const [showSmsBox, setShowSmsBox] = useState(false); // New state for toggling SMS box visibility
	const notify = useNotify();
	const { record, isLoading } = useShowController(props);
	const [tasks, setTasks] = useState([]);
	const [loadingTasks, setLoadingTasks] = useState(true);
	const [settingUpReminders, setSettingUpReminders] = useState(false);
	const refresh = useRefresh();

	const [showFcmBox, setShowFcmBox] = useState(false);
	const [fcmTitle, setFcmTitle] = useState("");
	const [fcmBody, setFcmBody] = useState("");
	const [fcmData, setFcmData] = useState(
		JSON.stringify(
			{
				target_url: "https://app.letterlife.se/app/settings",
				send_data: "false",
				app_badge_number: "3",
			},
			null,
			2,
		),
	);

	useEffect(() => {
		console.log("Record:", record);
		console.log("Phone number:", record?.phoneNumber);
	}, [record]);

	useEffect(() => {
		const fetchTasks = async () => {
			if (!record) return;
			try {
				const apiUrl = customDataProvider.getApiUrl();
				const response = await fetch(`${apiUrl}/users/${record.id}/tasks`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					setTasks(data);
				} else {
					notify("Failed to fetch user tasks", { type: "error" });
				}
			} catch (error) {
				notify("Error fetching user tasks", { type: "error" });
			} finally {
				setLoadingTasks(false);
			}
		};

		fetchTasks();
	}, [record, notify]);

	const handleSendSms = async () => {
		if (!record) return;
		try {
			const apiUrl = customDataProvider.getApiUrl();

			const response = await fetch(`${apiUrl}/users/${record.id}/sms`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
				},
				body: JSON.stringify({ message: smsMessage }),
			});
			if (response.ok) {
				notify("SMS sent successfully", { type: "success" });
				setSmsMessage("");
			} else {
				notify("Failed to send SMS", { type: "error" });
			}
		} catch (error) {
			notify("Error sending SMS", { type: "error" });
		}
	};

	const handleSendFcm = async () => {
		if (!record || !record.fcmToken) return;
		try {
			const apiUrl = customDataProvider.getApiUrl();
			const response = await fetch(`${apiUrl}/users/${record.id}/fcm`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
				},
				body: JSON.stringify({
					title: fcmTitle,
					body: fcmBody,
					data: fcmData ? JSON.parse(fcmData) : {},
				}),
			});
			if (response.ok) {
				notify("FCM sent successfully", { type: "success" });
				setFcmTitle("");
				setFcmBody("");
				setFcmData("");
			} else {
				notify("Failed to send FCM", { type: "error" });
			}
		} catch (error) {
			notify("Error sending FCM", { type: "error" });
		}
	};

	const handleSetupLogReminders = async () => {
		if (!record) return;
		setSettingUpReminders(true);
		try {
			const apiUrl = customDataProvider.getApiUrl();
			const response = await fetch(
				`${apiUrl}/users/${record.id}/setupLogReminders`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
					},
				},
			);
			const json = await response.json();
			if (json.result) {
				notify("Log reminders set up successfully", { type: "success" });
				refresh();
			} else {
				notify(`Failed to set up log reminders: ${json.message}`, {
					type: "error",
				});
			}
		} catch (error) {
			notify("Error setting up log reminders", { type: "error" });
		} finally {
			setSettingUpReminders(false);
		}
	};

	if (isLoading) return <CircularProgress />;

	const TruncatedFcmToken = () => {
		const record = useRecordContext();
		if (!record || !record.fcmToken) return null;
		return (
			<Typography variant="body2" component="span">
				{record.fcmToken.substring(0, 20)}...
			</Typography>
		);
	};

	return (
		<Show
			{...props}
			title={
				<>
					<span>View </span>
					<UserTitle />
				</>
			}
		>
			<SimpleShowLayout>
				{record?.removalPending && (
					<Alert severity="error" sx={{ marginBottom: 2 }}>
						USER IS PENDING REMOVAL
					</Alert>
				)}
				{record?.removed && (
					<Alert severity="error" sx={{ marginBottom: 2 }}>
						USER IS REMOVED
					</Alert>
				)}
				<Card sx={{ marginBottom: 2 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							User Information
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="id" />
										</PaddedLabeled>
									</Grid>
									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="phpId" />
										</PaddedLabeled>
									</Grid>
									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="email" />
										</PaddedLabeled>
									</Grid>
									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="firstName" />
										</PaddedLabeled>
									</Grid>

									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="tier" />
										</PaddedLabeled>
									</Grid>

									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="testGroup" />
										</PaddedLabeled>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid container spacing={3}>
									<Grid item xs={4}>
										<PaddedLabeled>
											<CustomDateField source="createdAt" />
										</PaddedLabeled>
									</Grid>
									<Grid item xs={4}>
										<PaddedLabeled>
											<CustomDateField source="lastSeenAt" />
										</PaddedLabeled>
									</Grid>
									<Grid item xs={4}>
										<PaddedLabeled>
											<TextField source="countryId" />
										</PaddedLabeled>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card sx={{ marginBottom: 2 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Device Information
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<PaddedLabeled label="App Device Type">
									<TextField source="deviceData.appDeviceType" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={4}>
								<PaddedLabeled label="App Version">
									<TextField source="deviceData.appVersion" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={4}>
								<PaddedLabeled label="FCM Token">
									<TruncatedFcmToken />
								</PaddedLabeled>
								{record && record.fcmToken && (
									<>
										<MuiButton
											variant="outlined"
											onClick={() => setShowFcmBox(!showFcmBox)}
											sx={{ marginTop: 2 }}
										>
											{showFcmBox ? "Hide FCM Box" : "Send FCM"}
										</MuiButton>
										{showFcmBox && (
											<Card sx={{ marginTop: 2 }}>
												<CardContent>
													<Typography variant="h6" gutterBottom>
														Send FCM
													</Typography>
													<MuiTextField
														fullWidth
														label="Title"
														variant="outlined"
														value={fcmTitle}
														onChange={(e) => setFcmTitle(e.target.value)}
														margin="normal"
													/>
													<MuiTextField
														fullWidth
														label="Body"
														variant="outlined"
														value={fcmBody}
														onChange={(e) => setFcmBody(e.target.value)}
														margin="normal"
													/>
													<MuiTextField
														fullWidth
														label="Data (JSON)"
														variant="outlined"
														value={fcmData}
														onChange={(e) => setFcmData(e.target.value)}
														margin="normal"
														multiline
														rows={5}
													/>
													<MuiButton
														variant="contained"
														onClick={handleSendFcm}
														disabled={!fcmTitle.trim() || !fcmBody.trim()}
														sx={{ marginTop: 2 }}
													>
														Send FCM
													</MuiButton>
												</CardContent>
											</Card>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card sx={{ marginBottom: 2 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Notifications and Reminders
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="notificationType" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="notificationsHandledByApp" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="phoneNumber" />
								</PaddedLabeled>
								{record && record.phoneNumber && (
									<>
										<MuiButton
											variant="outlined"
											onClick={() => setShowSmsBox(!showSmsBox)}
											sx={{ marginTop: 2 }}
										>
											{showSmsBox ? "Hide SMS Box" : "Send SMS"}
										</MuiButton>
										{showSmsBox && (
											<Card sx={{ marginTop: 2 }}>
												<CardContent>
													<Typography variant="h6" gutterBottom>
														Send SMS
													</Typography>
													<MuiTextField
														fullWidth
														label="SMS Message"
														variant="outlined"
														value={smsMessage}
														onChange={(e) => setSmsMessage(e.target.value)}
														margin="normal"
													/>
													<MuiButton
														variant="contained"
														onClick={handleSendSms}
														disabled={!smsMessage.trim()}
														sx={{ marginTop: 2 }}
													>
														Send SMS
													</MuiButton>
												</CardContent>
											</Card>
										)}
									</>
								)}
							</Grid>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="communityAckRules" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="timeOffsetHours" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="reminderTimes" />
								</PaddedLabeled>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card sx={{ marginBottom: 2 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Account Status
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="removalPending" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<TextField source="removed" />
								</PaddedLabeled>
							</Grid>
							<Grid item xs={12} sm={6}>
								<PaddedLabeled>
									<CustomDateField source="removedAt" />
								</PaddedLabeled>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card sx={{ marginBottom: 2 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Tasks for User
						</Typography>
						<MuiButton
							variant="outlined"
							onClick={handleSetupLogReminders}
							disabled={settingUpReminders}
							sx={{ marginBottom: 2 }}
						>
							{settingUpReminders ? "Setting up..." : "Setup Log Reminders"}
						</MuiButton>
						{loadingTasks ? (
							<CircularProgress />
						) : (
							<List
								resource={`users/${record.id}/tasks`}
								title=" "
								empty={<>No tasks</>}
								actions={false}
								hasCreate={false}
							>
								<Datagrid bulkActionButtons={false}>
									<TextField source="name" />
									<TextField source="scheduleTime" />
									<CustomDateField source="scheduleTime" />
									<TextField source="payload.action" />
									<TextField source="payload.userId" />
								</Datagrid>
							</List>
						)}
					</CardContent>
				</Card>

				<EventRecordsList />

				<TodoItemsList />
			</SimpleShowLayout>
		</Show>
	);
};
