import React from "react";
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, Show, SimpleShowLayout, BulkDeleteButton } from "react-admin";

import { withDefaultLocale } from "../components/withDefaultLocale";
import { useEscapeToList } from "../hooks/useEscapeToList";

export const ReactionCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/reactions");

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <TextInput source="objectId" />
                <TextInput source="userId" />
                <TextInput source="reactionType" />
                <TextInput source="created" />
            </SimpleForm>
        </Create>
    );
});

export const ReactionEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/reactions");

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextInput source="objectId" />
                    <TextInput source="userId" />
                    <TextInput source="reactionType" />
                    <TextInput source="created" />
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
});

export const ReactionShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/reactions");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="objectId" />
                <TextField source="userId" />
                <TextField source="reactionType" />
                <TextField source="created" />
            </SimpleShowLayout>
        </Show>
    );
});

export const ReactionList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source="objectId" label="objectId" />
                <TextField source="objectType" />
                <TextField source="userId" label="userId" />
                <TextField source="reactionType" />
                <TextField source="created" />
                <EditButton />
            </Datagrid>
        </List>
    );
});
