import React from "react";
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	Edit,
	SimpleForm,
	TextInput,
	Create,
	Show,
	SimpleShowLayout,
	BulkDeleteButton,
	NumberInput,
	DateTimeInput,
} from "react-admin";

import { useEscapeToList } from "../hooks/useEscapeToList";
import CustomDateField from "../components/CustomDateField";
import PaddedLabeled from "../components/PaddedLabeled";
import UserIdField from "../components/UserIdField";

export const TodoCreate = (props) => {
	useEscapeToList("/tools/todos");

	return (
		<Create {...props} mutationMode="optimistic">
			<SimpleForm>
				<TextInput source="userId" />
				<TextInput source="task" />
				<TextInput source="group" />
				<TextInput source="state" />
				<DateTimeInput source="stateChangedAt" />
				<NumberInput source="sortOrder" />
			</SimpleForm>
		</Create>
	);
};

export const TodoEdit = (props) => {
	useEscapeToList("/tools/todos");

	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm>
				<TextInput disabled source="id" />
				<TextInput source="userId" />
				<TextInput source="task" />
				<TextInput source="group" />
				<TextInput source="state" />
				<DateTimeInput source="stateChangedAt" />
				<NumberInput source="sortOrder" />
			</SimpleForm>
		</Edit>
	);
};

export const TodoShow = (props) => {
	useEscapeToList("/tools/todos");

	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<PaddedLabeled label="User">
					<UserIdField />
				</PaddedLabeled>
				<TextField source="task" />
				<TextField source="group" />
				<TextField source="state" />
				<CustomDateField source="stateChangedAt" />
				<TextField source="sortOrder" />
			</SimpleShowLayout>
		</Show>
	);
};

export const TodoList = (props) => {
	return (
		<List {...props}>
			<Datagrid
				rowClick="show"
				bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
			>
				<TextField source="id" />
				<TextField source="userId" />
				<TextField source="task" />
				<TextField source="group" />
				<TextField source="state" />
				<CustomDateField source="stateChangedAt" />
				<TextField source="sortOrder" />
				<EditButton />
			</Datagrid>
		</List>
	);
};
