import React, { useEffect, useState } from "react";
import {
    useDataProvider,
    TranslatableInputs,
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    Show,
    SimpleShowLayout,
    SelectInput,
    SelectField,
    BulkDeleteButton,
} from "react-admin";

import LocaleTabs from "../components/LocaleTabs";
import { withDefaultLocale } from "../components/withDefaultLocale";
import { useLocales } from "../contexts/LocalesContext";

import VerifyForumReplyTranslations from "./VerifyForumReplyTranslations";
import { useEscapeToList } from "../hooks/useEscapeToList";

export const ForumReplyCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    const dataProvider = useDataProvider();
    const [forumTopics, setForumTopics] = useState([]);
    useEscapeToList("/forum/replies");

    useEffect(() => {
        // Fetch the content tags
        dataProvider
            .getList("forum/topics", {
                pagination: { page: 1, perPage: 1000 }, // Adjust pagination as needed
                // sort: { field: "topic.sv-SE", order: "ASC" }, // Adjust sorting as needed
                filter: {},
            })
            .then(({ data }) => {
                // Map the content tags to the format required by SelectArrayInput
                const tagChoices = data.map((tag) => ({
                    id: tag.id,
                    name: tag.title[sessionStorage.getItem("selectedLocale")],
                }));
                setForumTopics(tagChoices);
            });
    }, [dataProvider]);

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <SelectInput source="forumTopicId" choices={forumTopics} label="Forum topic" />
                <TextInput source="parentId" />
                <TextInput source="content" label="Content" />
            </SimpleForm>
        </Create>
    );
});

export const ForumReplyEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    const dataProvider = useDataProvider();
    const [forumTopics, setForumTopics] = useState([]);
    useEscapeToList("/forum/replies");

    useEffect(() => {
        // Fetch the content tags
        dataProvider
            .getList("forum/topics", {
                pagination: { page: 1, perPage: 1000 }, // Adjust pagination as needed
                // sort: { field: "topic.sv-SE", order: "ASC" }, // Adjust sorting as needed
                filter: {},
            })
            .then(({ data }) => {
                // Map the content tags to the format required by SelectArrayInput
                const tagChoices = data.map((tag) => ({
                    id: tag.id,
                    name: tag.title[sessionStorage.getItem("selectedLocale")],
                }));
                setForumTopics(tagChoices);
            });
    }, [dataProvider]);

    const { locales } = useLocales();

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <SelectInput source="forumTopicId" choices={forumTopics} label="Forum topic" />
                    <TextInput source="parentId" />
                    <TextInput source="created" />
                    <TextInput source="userId" />
                    <TextInput source="content" />
                    <TextInput source="language" />
                </SimpleShowLayout>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="translatedContent" />
                </TranslatableInputs>
            </SimpleForm>
        </Edit>
    );
});

export const ForumReplyShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    const dataProvider = useDataProvider();
    const [forumTopics, setForumTopics] = useState([]);
    useEscapeToList("/forum/replies");

    useEffect(() => {
        // Fetch the content tags
        dataProvider
            .getList("forum/topics", {
                pagination: { page: 1, perPage: 1000 }, // Adjust pagination as needed
                // sort: { field: "topic.sv-SE", order: "ASC" }, // Adjust sorting as needed
                filter: {},
            })
            .then(({ data }) => {
                // Map the content tags to the format required by SelectArrayInput
                const tagChoices = data.map((tag) => ({
                    id: tag.id,
                    name: tag.title[sessionStorage.getItem("selectedLocale")],
                }));
                setForumTopics(tagChoices);
            });
    }, [dataProvider]);

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <SelectField source="forumTopicId" choices={forumTopics} label="Forum topic" />
                <TextField source="forumTopicId" label="forumTopicId" />
                <TextField source="parentId" />
                <TextField source="created" />
                <TextField source="userId" />
                <TextField source="content" />
                <TextField source="language" />
            </SimpleShowLayout>
        </Show>
    );
});

export const ForumReplyList = withDefaultLocale(({ defaultLocale, ...props }) => {
    const dataProvider = useDataProvider();
    const [forumTopics, setForumTopics] = useState([]);

    useEffect(() => {
        // Fetch the content tags
        dataProvider
            .getList("forum/topics", {
                pagination: { page: 1, perPage: 1000 }, // Adjust pagination as needed
                // sort: { field: "topic.sv-SE", order: "ASC" }, // Adjust sorting as needed
                filter: {},
            })
            .then(({ data }) => {
                // Map the content tags to the format required by SelectArrayInput
                const tagChoices = data.map((tag) => ({
                    id: tag.id,
                    name: tag.title[sessionStorage.getItem("selectedLocale")],
                }));
                setForumTopics(tagChoices);
            });
    }, [dataProvider]);

    return (
        <>
            <VerifyForumReplyTranslations resource="forum/replies" />
            <List {...props}>
                <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                    <TextField source="id" />
                    <TextField source="phpId" />
                    <SelectField source="forumTopicId" choices={forumTopics} label="Forum topic" />
                    <TextField source="parentId" />
                    <TextField source="created" />
                    <TextField source="userId" />
                    <TextField source="content" />
                    <TextField source="language" />
                    <EditButton />
                </Datagrid>
            </List>
        </>
    );
});
