import React from "react";
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, TextInput, Create, Show, SimpleShowLayout, BulkDeleteButton } from "react-admin";

import { withDefaultLocale } from "../components/withDefaultLocale";
import { useEscapeToList } from "../hooks/useEscapeToList";

export const NotificationBarItemCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/notificationBarItems");

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <TextInput source="userId" />
                <TextInput source="key" />
            </SimpleForm>
        </Create>
    );
});

export const NotificationBarItemEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/notificationBarItems");

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                </SimpleShowLayout>
                <TextInput source="userId" />
                <TextInput source="key" />
            </SimpleForm>
        </Edit>
    );
});

export const NotificationBarItemShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/notificationBarItems");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="userId" />
                <TextField source="key" />
            </SimpleShowLayout>
        </Show>
    );
});

export const NotificationBarItemList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="createdAt" />
                <TextField source="key" />
                <TextField source="userId" />
                <TextField source="text" />
                <TextField source="targetUrl" />
                <EditButton />
            </Datagrid>
        </List>
    );
});
