// import { useFormContext, useRecordContext } from "react-admin";
import { Box, Typography, Button as MuiButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRecordContext } from "react-admin";
import { useResourceContext } from "react-admin";

const ProductionBlocker = () => {
    const record = useRecordContext();
    const resource = useResourceContext();

    const mode = record ? "Edit" : "Create";
    const entityType = resource ? resource : "[unknown resource]";

    const navigate = useNavigate();
    const isProduction = localStorage.getItem("selectedEnvironment") === "production";
    if (isProduction) {
        return (
            <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                bgcolor="rgba(255, 255, 255, 0.7)"
                zIndex={1000}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Typography variant="h6">
                    {mode} of {entityType} is disabled in production environment.
                </Typography>
                <MuiButton variant="contained" sx={{ padding: "4px 8px", marginTop: 2 }} onClick={() => navigate(-1)}>
                    Back
                </MuiButton>
            </Box>
        );
    }
};

export default ProductionBlocker;
