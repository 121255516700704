import React from "react";
import { Tabs, Tab } from "@mui/material";
import { useTranslatableContext } from "react-admin";
import { useLocales } from "../contexts/LocalesContext";

const LocaleTabs = () => {
    const { selectLocale, selectedLocale } = useTranslatableContext();

    const { locales } = useLocales();

    const handleChange = (event, newLocale) => {
        selectLocale(newLocale);
        sessionStorage.setItem("selectedLocale", newLocale);
    };

    return (
        <Tabs value={selectedLocale} onChange={handleChange} aria-label="Locale Tabs" indicatorColor="primary" textColor="primary">
            {locales.map((locale) => (
                <Tab key={locale.code} label={locale.name} value={locale.code} />
            ))}
        </Tabs>
    );
};

export default LocaleTabs;
