// src/contexts/LocalesContext.js

import React, { createContext, useContext, useState, useEffect } from "react";
import { useLogin } from "react-admin";
import dataProvider from "../dataProvider";

const LocalesContext = createContext();

export const useLocales = () => {
    return useContext(LocalesContext);
};

export const LocalesProvider = ({ children }) => {
    const [locales, setLocales] = useState([]);
    const [loading, setLoading] = useState(true);
    const login = useLogin();

    useEffect(() => {
        const fetchLocales = async () => {
            if (locales.length === 0) {
                setLoading(true); // Ensure loading state is set before fetching
                try {
                    const data = await dataProvider.getGlobals();
                    setLocales(data.locales);
                } catch (error) {
                    console.error("Failed to fetch locales:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false); // If locales are already fetched, set loading to false
            }
        };

        // Call fetchLocales when user logs in
        fetchLocales();
    }, [login, locales]); // Dependencies include login and locales

    return <LocalesContext.Provider value={{ locales, loading }}>{children}</LocalesContext.Provider>;
};
