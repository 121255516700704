// src/customTheme.js
import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
    palette: {
        primary: {
            main: "#2196f3",
        },
        secondary: {
            main: "#f50057",
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    background: "transparent",
                    boxShadow: "0px 0px 0px 0px",
                },
            },
        },
        RaSimpleShowLayout: {
            styleOverrides: {
                root: {
                    background: "transparent",
                    padding: "7.5px 10px",
                },
            },
        },
        RaLabeled: {
            styleOverrides: {
                root: {
                    "&.MuiStack-root": {
                        marginBottom: "0",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    "&.MuiCard-root": {
                        background: "#fff",
                        borderRadius: 3,
                        border: "1px solid #ddd",
                    },
                    "&.RaList-content": {
                        border: 0,
                    },
                    "&.RaEdit-card": {
                        background: "transparent",
                        // border: 0,
                        padding: 0,
                    },
                    "&.RaShow-card": {
                        background: "transparent",
                        // border: 0,
                        padding: 0,
                    },
                    boxShadow: "0px 0px 0px 0px",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 10,
                    "&:last-child": {
                        padding: 10,
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    "&.RaDatagrid-headerCell": {
                        backgroundColor: "#eee",
                        fontWeight: "bold",
                        cursor: "pointer",
                        padding: 8,
                        "&:hover": {
                            backgroundColor: "#e8e8e8",
                        },
                        borderRadius: 0,
                        "&:first-of-type": {
                            textAlign: "center",
                        },
                    },
                },
                head: {
                    backgroundColor: "#eee",
                    fontWeight: "bold",
                    "&:hover": {
                        backgroundColor: "#e8e8e8",
                    },
                    padding: 8,
                    lineHeight: 1,
                    borderRight: "1px solid #fff",
                    borderBottom: 0,
                },
                body: {
                    padding: 8,
                    "&:hover": {
                        backgroundColor: "#f7f7f7",
                    },
                    borderColor: "#eeeeee",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.MuiInputLabel-sizeMedium": {
                        fontSize: "0.8rem",
                        lineHeight: 0.5,
                        overflow: "visible!important",
                        opacity: 0.5,
                    },
                    "&.MuiInputLabel-sizeMedium.Mui-focused": {
                        fontSize: "1rem",
                        lineHeight: 1.4375,
                        overflow: "visible!important",
                        opacity: 1,
                    },
                    "&.MuiFormLabel-filled": {
                        fontSize: "1rem",
                        lineHeight: 1.4375,
                        opacity: 1,
                    },
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    marginTop: "0!important",
                    "&.RaLabeled-root": {
                        color: "red",
                        marginBottom: "8px!important",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: "8px 12px",
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 14,
                },
            },
        },
        RaDatagrid: {
            styleOverrides: {
                headerCell: {
                    backgroundColor: "#eee",
                    fontWeight: "bold",
                    cursor: "pointer",
                    "&:hover": {
                        backgroundColor: "#e8e8e8",
                    },
                },
            },
        },
        RaList: {
            styleOverrides: {
                border: "0",
            },
        },
    },
});

export default customTheme;
