import React from "react";
import { TextField, Button, useRecordContext } from "react-admin";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { Box } from "@mui/material";

const UserLinkButton = () => {
	const record = useRecordContext();
	return (
		<Button
			component={Link}
			to={`/users/${record.userId}/show`}
			title="Go to User"
			size="small"
		>
			<PersonIcon fontSize="small" />
		</Button>
	);
};

const UserIdField = () => {
	return (
		<Box display="flex" alignItems="center">
			<TextField source="userId" />
			<UserLinkButton />
		</Box>
	);
};

export default UserIdField;
