import React from "react";
import {
	InfiniteList,
	Datagrid,
	TextField,
	SimpleForm,
	TextInput,
	Create,
	Show,
	SimpleShowLayout,
	DateField,
	FunctionField,
	BulkDeleteButton,
} from "react-admin";
import { useEscapeToList } from "../hooks/useEscapeToList";
import CustomDateField from "../components/CustomDateField";
import { TopToolbar, DeleteButton } from "react-admin";
import { Grid } from "@mui/material";
import PaddedLabeled from "../components/PaddedLabeled";
import UserIdField from "../components/UserIdField";

export const EventRecordCreate = (props) => {
	useEscapeToList("/eventRecords");

	return (
		<Create {...props} mutationMode="optimistic">
			<SimpleForm>
				<TextInput source="eventType" />
				<TextInput source="eventName" />
				<TextInput source="value" />
				<TextInput source="metadata" multiline />
				<TextInput source="userId" />
			</SimpleForm>
		</Create>
	);
};

const PostShowActions = () => {
	return (
		<TopToolbar>
			<DeleteButton mutationMode="pessimistic" />
		</TopToolbar>
	);
};

export const EventRecordShow = (props) => {
	useEscapeToList("/eventRecords");

	return (
		<Show {...props} actions={<PostShowActions />}>
			<SimpleShowLayout>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<PaddedLabeled>
							<TextField source="id" />
						</PaddedLabeled>
					</Grid>

					<Grid item xs={3}>
						<PaddedLabeled>
							<DateField source="createdAt" showTime />
						</PaddedLabeled>
					</Grid>
					<Grid item xs={6}>
						<PaddedLabeled label="User">
							<UserIdField />
						</PaddedLabeled>
					</Grid>
					<Grid item xs={3}>
						<PaddedLabeled>
							<TextField source="eventType" />
						</PaddedLabeled>
					</Grid>
					<Grid item xs={3}>
						<PaddedLabeled>
							<TextField source="eventName" />
						</PaddedLabeled>
					</Grid>
					<Grid item xs={6}>
						<PaddedLabeled>
							<TextField source="value" />
						</PaddedLabeled>
					</Grid>
					<Grid item xs={6} />
					<Grid item xs={6}>
						<PaddedLabeled>
							<TextField source="metadata" />
						</PaddedLabeled>
					</Grid>
				</Grid>
			</SimpleShowLayout>
		</Show>
	);
};

export const EventRecordList = (props) => {
	return (
		<InfiniteList {...props}>
			<Datagrid
				rowClick="show"
				bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
			>
				<TextField source="id" />
				<TextField source="userId" />
				<CustomDateField source="createdAt" />
				<TextField source="eventType" />
				<TextField source="eventName" />
				<FunctionField
					source="value"
					render={(record) => {
						const str =
							typeof record.value === "object"
								? JSON.stringify(record.value)
								: record.value;

						if (str.length > 50) {
							return `${str.substring(0, 47)}...`;
						}
						return str;
					}}
				/>
				<FunctionField
					source="metadata"
					render={(record) => {
						const str =
							typeof record.metadata === "object"
								? JSON.stringify(record.metadata)
								: record.metadata;

						if (str.length > 50) {
							return `${str.substring(0, 47)}...`;
						}
						return str;
					}}
				/>
			</Datagrid>
		</InfiniteList>
	);
};
