import React, { useEffect, useState } from "react";
import {
    useDataProvider,
    TranslatableInputs,
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    Show,
    SimpleShowLayout,
    TranslatableFields,
    RadioButtonGroupInput,
    ImageField,
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Labeled,
    BulkDeleteButton,
} from "react-admin";

import LocaleTabs from "../components/LocaleTabs";
import { withDefaultLocale } from "../components/withDefaultLocale";
import { useLocales } from "../contexts/LocalesContext";
import { useEscapeToList } from "../hooks/useEscapeToList";

import ImageSelector from "../components/ImageSelector";

// Define the possible status values
const statusChoices = [
    { id: "draft", name: "Draft" },
    { id: "active", name: "Active" },
    { id: "archived", name: "Archived" },
];

export const ForumTopicCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    const dataProvider = useDataProvider();
    const [contentTags, setContentTags] = useState([]);
    useEscapeToList("/forum/topics");

    useEffect(() => {
        // Fetch the content tags
        dataProvider
            .getList("contentTags", {
                pagination: { page: 1, perPage: 100 }, // Adjust pagination as needed
                sort: { field: "internalName", order: "ASC" }, // Adjust sorting as needed
                filter: {},
            })
            .then(({ data }) => {
                // Map the content tags to the format required by SelectArrayInput
                const tagChoices = data.map((tag) => ({
                    id: tag.id,
                    name: tag.internalName,
                }));
                setContentTags(tagChoices);
            });
    }, [dataProvider]);

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                    <TextInput source="description" />
                </TranslatableInputs>

                <TextInput source="publishedAt" />
                <ImageSelector source="imageUrl" />

                <RadioButtonGroupInput source="status" label="Status" choices={statusChoices} />

                <SelectArrayInput source="contentTagIds" choices={contentTags} label="Content Tags" />
            </SimpleForm>
        </Create>
    );
});

export const ForumTopicEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    const dataProvider = useDataProvider();
    const [contentTags, setContentTags] = useState([]);
    useEscapeToList("/forum/topics");

    useEffect(() => {
        // Fetch the content tags
        dataProvider
            .getList("contentTags", {
                pagination: { page: 1, perPage: 100 }, // Adjust pagination as needed
                sort: { field: "internalName", order: "ASC" }, // Adjust sorting as needed
                filter: {},
            })
            .then(({ data }) => {
                // Map the content tags to the format required by SelectArrayInput
                const tagChoices = data.map((tag) => ({
                    id: tag.id,
                    name: tag.internalName,
                }));
                setContentTags(tagChoices);
            });
    }, [dataProvider]);

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                </SimpleShowLayout>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                    <TextInput source="description" />
                </TranslatableInputs>

                <TextInput source="publishedAt" />
                <ImageSelector source="imageUrl" />

                <Labeled>
                    <ImageField source="imageRoundedUrl" title="imageRoundedUrl" />
                </Labeled>

                <RadioButtonGroupInput source="status" label="Status" choices={statusChoices} />

                <SelectArrayInput source="contentTagIds" choices={contentTags} label="Content Tags" />
            </SimpleForm>
        </Edit>
    );
});

export const ForumTopicShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/forum/topics");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TranslatableFields locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextField source="title" />
                    <TextField source="description" />
                </TranslatableFields>
                <ImageField source="imageUrl" title="title" sx={{ "& img": { maxWidth: 100 } }} />
                <ImageField source="imageRoundedUrl" title="imageRoundedUrl" />
                <TextField source="buttonColor" label="buttonColor" />
                <TextField source="buttonBorder" label="buttonBorder" />
                <TextField source="buttonBackground" label="buttonBackground" />

                <TextField source="replyCount" label="Replies" />
                <TextField source={`reactionCounts.like`} label="Likes" />

                <TextField source="status" label="Status" />
                <TextField source="publishedAt" />
                <ReferenceArrayField reference="contentTags" source="contentTagIds" label="Tags">
                    <SingleFieldList>
                        <ChipField source="internalName" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
});

export const ForumTopicList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="phpId" />

                <ImageField source="imageUrl" title="title" className="list-image" />
                <ImageField source="imageRoundedUrl" title="rounded" className="list-image" />
                <TextField source="status" label="Status" />
                <TextField source="publishedAt" />

                <TextField source="replyCount" label="Replies" />
                <TextField source={`reactionCounts.like`} label="Likes" />

                <TextField source={`title.${defaultLocale}`} label={`Title (${defaultLocale})`} />
                <TextField source={`content.${defaultLocale}`} label={`Content (${defaultLocale})`} />
                <ReferenceArrayField reference="contentTags" source="contentTagIds" label="Tags">
                    <SingleFieldList>
                        <ChipField source="internalName" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <EditButton />
            </Datagrid>
        </List>
    );
});
