import React, { useState } from "react";
import {
	List,
	Datagrid,
	TextField,
	EditButton,
	BulkDeleteButton,
	SearchInput,
	TopToolbar,
	CreateButton,
	ExportButton,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { TextField as MuiTextField, Button as MuiButton } from "@mui/material";
import CustomDateField from "../../components/CustomDateField";

const ListActions = () => {
	const [userId, setUserId] = useState("");
	const navigate = useNavigate();

	const handleSubmit = () => {
		if (userId) {
			navigate(`/users/${userId}/show`);
		}
	};
	return (
		<TopToolbar>
			<MuiTextField
				value={userId}
				onChange={(e) => setUserId(e.target.value)}
				placeholder="Enter User ID"
				variant="outlined"
				size="small"
				sx={{
					width: 200,
					backgroundColor: "background.paper",
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: "rgba(0, 0, 0, 0.23)",
						},
						"&:hover fieldset": {
							borderColor: "rgba(0, 0, 0, 0.5)",
						},
					},
				}}
			/>
			<MuiButton variant="contained" onClick={handleSubmit}>
				Go
			</MuiButton>
			<CreateButton />
			<ExportButton />
		</TopToolbar>
	);
};

export const UserList = (props) => {
	const [searchActive, setSearchActive] = useState(false);

	const handleSearch = (event) => {
		setSearchActive(event.target.value.length > 0);
	};

	return (
		<>
			<List
				actions={<ListActions />}
				filters={[
					<SearchInput
						key="userSearch"
						source="q"
						placeholder="Email..."
						alwaysOn
						onChange={handleSearch}
					/>,
				]}
				{...props}
			>
				<Datagrid
					rowClick="show"
					bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
				>
					<TextField source="id" sortable={!searchActive} />
					<CustomDateField source="createdAt" sortable={!searchActive} />
					<TextField source="email" sortable={!searchActive} />
					<TextField source="tier" sortable={!searchActive} />
					<CustomDateField source="lastSeenAt" sortable={!searchActive} />
					<TextField source="removed" sortable={!searchActive} />
					<TextField source="testGroup" sortable={!searchActive} />
					<TextField source="notificationType" sortable={!searchActive} />
					<TextField source="remindersHandledByApp" sortable={!searchActive} />
					<TextField source="firebaseAuth" sortable={!searchActive} />
					<EditButton />
				</Datagrid>
			</List>
		</>
	);
};
