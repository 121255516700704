import React from "react";
import {
    TranslatableInputs,
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    Show,
    SimpleShowLayout,
    TranslatableFields,
    NumberInput,
    ImageField,
    BulkDeleteButton,
} from "react-admin";

import LocaleTabs from "../components/LocaleTabs";
import { withDefaultLocale } from "../components/withDefaultLocale";
import { useLocales } from "../contexts/LocalesContext";
import { useEscapeToList } from "../hooks/useEscapeToList";

import ImageSelector from "../components/ImageSelector";

export const ContentCategoryCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/contentCategories");

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <TextInput source="internalName" label="Internal name" />
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                </TranslatableInputs>
                <NumberInput source="sortOrder" label="Sort order" />
            </SimpleForm>
        </Create>
    );
});

export const ContentCategoryEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/contentCategories");

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextInput source="internalName" />
                </SimpleShowLayout>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="title" />
                </TranslatableInputs>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="titleShort" />
                </TranslatableInputs>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextInput source="introContent" />
                </TranslatableInputs>

                <ImageSelector source="iconUrl" />

                <SimpleShowLayout>
                    <NumberInput source="sortOrder" />
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
});

export const ContentCategoryShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    const { locales } = useLocales();
    useEscapeToList("/contentCategories");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="internalName" />
                <TranslatableFields locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextField source="title" emptyText="" />
                </TranslatableFields>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextField source="titleShort" emptyText="" />
                </TranslatableInputs>
                <TranslatableInputs locales={locales.map((locale) => locale.code)} defaultLocale={defaultLocale} selector={<LocaleTabs />}>
                    <TextField source="introContent" emptyText="" />
                </TranslatableInputs>
                <ImageField source="iconUrl" title="title" className="list-image" />

                <TextField source="sortOrder" label="Sort order" />
            </SimpleShowLayout>
        </Show>
    );
});

export const ContentCategoryList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="phpId" />
                <ImageField source="iconUrl" title="title" className="list-image" />
                <TextField source="internalName" />
                <TextField source={`title.${defaultLocale}`} label={`Title (${defaultLocale})`} />
                <TextField source="sortOrder" label="Sort order" />
                <EditButton />
            </Datagrid>
        </List>
    );
});
