import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import customDataProvider from "./dataProvider";

const Dashboard = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch data from /hello endpoint
        fetch(`${customDataProvider.getApiUrl()}`)
            .then((response) => {
                if (!response.ok) {
                    setData("Network response was not ok: " + JSON.stringify(response));
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                setData(data);
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });
    }, []);

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Dashboard
                </Typography>
                {data ? (
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                ) : (
                    <Typography color="textSecondary" gutterBottom>
                        Loading...
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default Dashboard;
