import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useEscapeToList = (listPath) => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === "Escape") {
                navigate(listPath);
            }
        };

        document.addEventListener("keydown", handleEscKey);

        return () => {
            document.removeEventListener("keydown", handleEscKey);
        };
    }, [navigate, listPath]);
};
