import React from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	NumberInput,
	BooleanInput,
} from "react-admin";
import { Typography } from "@mui/material";
import { useEscapeToList } from "../../hooks/useEscapeToList";
import Grid from "@mui/material/Grid";
import ProductionBlocker from "../../components/ProductionBlocker";
import JsonArrayInput from "./JsonArrayInput";

export const UserCreate = (props) => {
	useEscapeToList("/users");

	return (
		<Create {...props} title="Create User">
			<ProductionBlocker />
			<SimpleForm>
				<Typography variant="h6" gutterBottom>
					User Information
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextInput source="email" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput source="tier" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<NumberInput source="testGroup" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput source="countryId" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput source="createdAt" fullWidth />
					</Grid>
				</Grid>

				<Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
					Device Information
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextInput source="deviceData.appDeviceType" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput source="deviceData.appVersion" fullWidth />
					</Grid>
					<Grid item xs={12}>
						<TextInput source="fcmToken" fullWidth />
					</Grid>
				</Grid>

				<Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
					Notifications and Reminders
				</Typography>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<TextInput source="notificationType" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<BooleanInput source="notificationsHandledByApp" />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput source="phoneNumber" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<TextInput source="communityAckRules" fullWidth />
					</Grid>
					<Grid item xs={12} md={6}>
						<NumberInput source="timeOffsetHours" fullWidth />
					</Grid>
					<Grid item xs={12}>
						<JsonArrayInput source="reminderTimes" />
					</Grid>
				</Grid>
			</SimpleForm>
		</Create>
	);
};
