import React from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    Show,
    SimpleShowLayout,
    BooleanInput,
    SelectInput,
    ArrayInput,
    SimpleFormIterator,
    BooleanField,
    BulkDeleteButton,
    ArrayField,
} from "react-admin";

import { withDefaultLocale } from "../components/withDefaultLocale";
import { useEscapeToList } from "../hooks/useEscapeToList";
import { JsonField, JsonInput } from "react-admin-json-view";

const typeChoices = [
    { id: "LOGGING", name: "Logging" },
    { id: "MEDICINE", name: "Medicine" },
    { id: "TODO", name: "Todo" },
    { id: "NIGHT_ROUTINE", name: "Night Routine" },
];

export const ReminderNotificationCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/reminderNotifications");

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <SelectInput source="type" choices={typeChoices} />
                <BooleanInput source="active" />
                <TextInput source="locale" />
                <TextInput source="title" />
                <TextInput source="subTitle" />
                <TextInput source="body" />
                <ArrayInput source="rules">
                    <SimpleFormIterator>
                        <TextInput source="key" />
                        <TextInput source="value" />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
});

export const ReminderNotificationEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/reminderNotifications");

    return (
        <Edit {...props} mutationMode="pessimistic" redirect="show">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="phpId" />
                </SimpleShowLayout>
                <SelectInput source="type" choices={typeChoices} />
                <BooleanInput source="active" />
                <TextInput source="locale" />
                <TextInput source="title" />
                <TextInput source="subTitle" />
                <TextInput source="body" />

                <JsonInput
                    source="rules"
                    validate={[]}
                    jsonString={false} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                        // Props passed to react-json-view
                        name: null,
                        collapsed: true,
                        enableClipboard: false,
                        displayDataTypes: false,
                    }}
                />
            </SimpleForm>
        </Edit>
    );
});

export const ReminderNotificationShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/reminderNotifications");

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source="type" />
                <BooleanField source="active" />
                <TextField source="locale" />
                <TextField source="title" />
                <TextField source="subTitle" />
                <TextField source="body" />
                <JsonField
                    source="rules"
                    jsonString={false} // Set to true if the value is a string, default: false
                    reactJsonOptions={{
                        // Props passed to react-json-view
                        name: null,
                        collapsed: true,
                        enableClipboard: false,
                        displayDataTypes: false,
                    }}
                />
            </SimpleShowLayout>
        </Show>
    );
});

export const ReminderNotificationList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source="type" />
                <BooleanField source="active" />
                <TextField source="locale" />
                <TextField source="title" />
                <TextField source="body" />
                <TextField source="subTitle" />
                <EditButton />
            </Datagrid>
        </List>
    );
});
