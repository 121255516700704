import { formatDistanceToNow, format } from "date-fns";
import { useRecordContext } from "react-admin";

const CustomDateField = ({ source }) => {
    const record = useRecordContext();
    if (!record || !record[source]) {
        return null;
    }
    const value = new Date(record[source]);
    const relativeTime = formatDistanceToNow(value, { addSuffix: true });
    const absoluteDate = format(value, "yyyy-MM-dd HH:mm:ss");

    return (
        <>
            <small style={{ opacity: 0.5, whiteSpace: "nowrap" }}>{absoluteDate}</small>
            <div>{relativeTime}</div>
        </>
    );
};

export default CustomDateField;
