// src/components/withDefaultLocale.js

import React from "react";
import { useDefaultLocale } from "../hooks/useDefaultLocale";

export const withDefaultLocale = (Component) => (props) => {
    const defaultLocale = useDefaultLocale();

    if (!defaultLocale) return null;

    return <Component {...props} defaultLocale={defaultLocale} />;
};
