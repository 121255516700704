import React from "react";
import {
	SimpleForm,
	TextInput,
	NumberInput,
	TextField,
	Edit,
	BooleanInput,
} from "react-admin";
import { Card, CardContent, Typography } from "@mui/material";
import { useEscapeToList } from "../../hooks/useEscapeToList";
import Grid from "@mui/material/Grid";
import { DateTimeInput } from "react-admin";
import PaddedLabeled from "../../components/PaddedLabeled";
import ProductionBlocker from "../../components/ProductionBlocker";
import JsonArrayInput from "./JsonArrayInput";
import UserTitle from "./UserTitle";

export const UserEdit = (props) => {
	useEscapeToList("/users");

	return (
		<Edit
			{...props}
			mutationMode="pessimistic"
			redirect="show"
			title={
				<>
					<span>Edit </span>
					<UserTitle />
				</>
			}
		>
			<ProductionBlocker />

			<SimpleForm>
				<Card sx={{ marginBottom: 2 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							User Information
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="id" />
										</PaddedLabeled>
									</Grid>
									<Grid item xs={6}>
										<PaddedLabeled>
											<TextField source="phpId" />
										</PaddedLabeled>
									</Grid>
									<Grid item xs={6}>
										<TextInput source="email" fullWidth />
									</Grid>
									<Grid item xs={6}>
										<TextInput source="firstName" fullWidth />
									</Grid>

									<Grid item xs={6}>
										<TextInput source="tier" fullWidth />
									</Grid>

									<Grid item xs={6}>
										<TextInput source="testGroup" fullWidth />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid container spacing={3}>
									<Grid item xs={4}>
										<DateTimeInput source="createdAt" fullWidth />
									</Grid>
									<Grid item xs={4}>
										<DateTimeInput source="lastSeenAt" fullWidth />
									</Grid>
									<Grid item xs={4}>
										<TextInput source="countryId" fullWidth />
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card sx={{ marginBottom: 2, width: 1 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Device Information
						</Typography>
						<Grid container spacing={3}>
							<Grid item xs={12} md={4}>
								<TextInput source="deviceData.appDeviceType" fullWidth />
							</Grid>
							<Grid item xs={12} md={4}>
								<TextInput source="deviceData.appVersion" fullWidth />
							</Grid>
							<Grid item xs={12} md={4}>
								<TextInput source="fcmToken" fullWidth />
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card sx={{ marginBottom: 2, width: 1 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Notifications and Reminders
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextInput source="notificationType" fullWidth />
							</Grid>
							<Grid item xs={12} sm={6}>
								<BooleanInput source="notificationsHandledByApp" />
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextInput source="phoneNumber" fullWidth />
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextInput source="communityAckRules" fullWidth />
							</Grid>
							<Grid item xs={12} sm={6}>
								<NumberInput source="timeOffsetHours" fullWidth />
							</Grid>
							<Grid item xs={12} sm={6}>
								<JsonArrayInput source="reminderTimes" />
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Card sx={{ marginBottom: 2, width: 1 }}>
					<CardContent>
						<Typography variant="h6" gutterBottom>
							Account Status
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<BooleanInput source="removalPending" />
							</Grid>
							<Grid item xs={12} sm={6}>
								<BooleanInput source="removed" />
							</Grid>
							<Grid item xs={12} sm={6}>
								<DateTimeInput source="removedAt" fullWidth />
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</SimpleForm>
		</Edit>
	);
};
