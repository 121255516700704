import React, { useState } from "react";
import { AppBar, TitlePortal } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";
import { IconButton, MenuItem, Select } from "@mui/material";
import { environmentBaseColors } from "../theme/colors";

const SettingsButton = () => (
    <IconButton color="inherit">
        <SettingsIcon />
    </IconButton>
);

export const CustomAppBar = () => {
    // Initialize state from localStorage or fallback to "development"
    const [environment, setEnvironmentState] = useState(() => localStorage.getItem("selectedEnvironment") || "development");

    const handleChange = (event) => {
        const selectedEnv = event.target.value;
        setEnvironmentState(selectedEnv);
        localStorage.setItem("selectedEnvironment", selectedEnv);
        console.log("Selected environment:", selectedEnv);
        // refresh app to apply changes
        window.location.reload();
    };

    // Determine the color based on the environment
    const selectedEnvironment = localStorage.getItem("selectedEnvironment") || "default";
    const appBarColor = environmentBaseColors[selectedEnvironment];

    // if in production, hide the settings dropdown
    const isInProduction = process.env.REACT_APP_ENV === "production";

    return (
        <AppBar
            sx={{
                backgroundColor: appBarColor,
                color: "white",
            }}
        >
            <TitlePortal />
            {!isInProduction && (
                <Select
                    value={environment}
                    onChange={handleChange}
                    displayEmpty
                    sx={{
                        color: "white",
                        "& .MuiSelect-select": {
                            padding: "8px 16px", // Adjust padding as needed
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                        },
                        "&:before, &:after": {
                            borderBottom: "none",
                        },
                    }}
                    MenuProps={{
                        PaperProps: {
                            sx: {
                                bgcolor: "#eeeeee",
                                "& .MuiMenuItem-root": {
                                    color: "#333333",
                                },
                            },
                        },
                    }}
                >
                    <MenuItem value="development">Development</MenuItem>
                    <MenuItem value="stage">Stage</MenuItem>
                    <MenuItem value="production">Production</MenuItem>
                </Select>
            )}
            <SettingsButton />
        </AppBar>
    );
};
