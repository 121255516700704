// src/dataProvider.js
import { fetchUtils } from "react-admin";
import jsonServerProvider from "ra-data-json-server";

let apiUrl = null;

// if in production, hide the settings dropdown
const isInProduction = process.env.REACT_APP_ENV === "production";

if (isInProduction) {
	localStorage.setItem("selectedEnvironment", "production");
}

switch (localStorage.getItem("selectedEnvironment")) {
	case "development":
		apiUrl = `${process.env.REACT_APP_API_BASE_URL_DEVELOPMENT}/v1`;
		break;
	case "stage":
		apiUrl = `${process.env.REACT_APP_API_BASE_URL_STAGE}/v1`;
		break;
	case "production":
		apiUrl = `${process.env.REACT_APP_API_BASE_URL_PRODUCTION}/v1`;
		break;
	default:
		apiUrl = `${process.env.REACT_APP_API_BASE_URL_DEVELOPMENT}/v1`;
		break;
}

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: "application/json" });
	}
	const jwt_access_token = localStorage.getItem("jwt_access_token");
	options.headers.set("Authorization", `Bearer ${jwt_access_token}`);
	return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(apiUrl, httpClient);

// export default dataProvider;

// Extend the data provider with a custom method
const customDataProvider = {
	...dataProvider,
	getGlobals: () => {
		const url = `${apiUrl}/globals`;
		return httpClient(url).then(({ json }) => json);
	},
	getApiUrl: () => {
		return apiUrl;
	},
};

export default customDataProvider;
