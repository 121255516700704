// src/hooks/useDefaultLocale.js

import { useState, useEffect } from "react";
import { useLocales } from "../contexts/LocalesContext";

export const useDefaultLocale = () => {
    const [defaultLocale, setDefaultLocale] = useState(null);
    const { locales, loading } = useLocales();

    useEffect(() => {
        if (loading) return; // Wait until locales are loaded

        const storedLocale = sessionStorage.getItem("selectedLocale");
        const localeCodes = locales.map((locale) => locale.code);

        if (storedLocale && localeCodes.includes(storedLocale)) {
            setDefaultLocale(storedLocale);
        } else {
            setDefaultLocale(localeCodes[0]); // Fallback to the first locale if no stored locale found
        }
    }, [locales, loading]);

    return defaultLocale;
};
