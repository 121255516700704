import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Chip } from "@mui/material";
import { useRecordContext, useGetList } from "react-admin";
import { formatDistanceToNow, format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { Tooltip } from "@mui/material";

const TodoItemsList = () => {
	const record = useRecordContext();
	const navigate = useNavigate();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "sortOrder", sort: "asc" },
	]);

	const { data, total, isLoading } = useGetList("tools/todos", {
		pagination: {
			page: paginationModel.page + 1,
			perPage: paginationModel.pageSize,
		},
		sort: {
			field: sortModel[0]?.field || "sortOrder",
			order: sortModel[0]?.sort?.toUpperCase() || "ASC",
		},
		filter: { userId: record.id },
	});

	const columns = [
		{
			field: "sortOrder",
			headerName: "Order",
			width: 100,
			type: "number",
		},
		{ field: "task", headerName: "Task", width: 300 },
		{
			field: "group",
			headerName: "Group",
			width: 150,
			renderCell: (params) => (
				<Chip
					label={params.value || "N/A"}
					sx={{
						backgroundColor:
							params.value === "now"
								? "#e2ecdd"
								: params.value === "soon"
									? "#faeddb"
									: params.value === "later"
										? "#fbdbdb"
										: "default",
						color: "text.primary",
					}}
				/>
			),
		},
		{
			field: "state",
			headerName: "State",
			width: 120,
			renderCell: (params) => (
				<Chip
					label={params.value || "open"}
					color={params.value === "completed" ? "success" : "primary"}
				/>
			),
		},
		{
			field: "stateChangedAt",
			headerName: "State Changed",
			width: 200,
			renderCell: (params) =>
				params.value ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<div style={{ padding: "10px 0" }}>
							<span
								style={{
									margin: 0,
									whiteSpace: "nowrap",
									display: "block",
									lineHeight: "1.2em",
								}}
							>
								{formatDistanceToNow(new Date(params.value), {
									addSuffix: true,
								})}
							</span>
							<span
								style={{
									fontSize: "0.8em",
									opacity: 0.5,
									margin: 0,
									whiteSpace: "nowrap",
									display: "block",
									lineHeight: "1.2em",
								}}
							>
								{format(params.value, "yyyy-MM-dd HH:mm:ss")}
							</span>
						</div>
					</div>
				) : (
					"N/A"
				),
		},
	];

	return (
		<Card sx={{ marginBottom: 2 }}>
			<CardContent>
				<Typography variant="h6" gutterBottom>
					Todo Items for User
				</Typography>
				{total === 0 ? (
					<Typography>- no records -</Typography>
				) : (
					<div style={{ height: 400, width: "100%" }}>
						<DataGrid
							rows={data || []}
							columns={columns}
							paginationModel={paginationModel}
							onPaginationModelChange={setPaginationModel}
							sortModel={sortModel}
							onSortModelChange={setSortModel}
							rowCount={total}
							loading={isLoading}
							paginationMode="server"
							sortingMode="server"
							disableRowSelectionOnClick
							onRowClick={(params) =>
								navigate(`/tools/todos/${params.id}/show`)
							}
							sx={{ cursor: "pointer" }}
							keepNonExistentRowsSelected
						/>
					</div>
				)}
			</CardContent>
		</Card>
	);
};

export default TodoItemsList;
