import React from "react";
import {
    TranslatableInputs,
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    Show,
    SimpleShowLayout,
    TranslatableFields,
    NumberInput,
    useRecordContext,
    useGetRecordId,
    useNotify,
    BulkDeleteButton,
} from "react-admin";
import { Button } from "@mui/material";
import customDataProvider from "../dataProvider";

import { withDefaultLocale } from "../components/withDefaultLocale";
import { useEscapeToList } from "../hooks/useEscapeToList";

export const ExpertQuestionCreate = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/tools/ask-expert/expertQuestions");

    return (
        <Create {...props} mutationMode="optimistic">
            <SimpleForm>
                <TextInput source="question" />
                <TextInput source="answer" />
                <TextInput source="askedByUserId" />
                <TextInput source="answeredByAuthorId" />
                <TextInput source="askedAt" />
                <TextInput source="answeredAt" />
                <TextInput source="isAnswered" />
            </SimpleForm>
        </Create>
    );
});

export const ExpertQuestionEdit = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/tools/ask-expert/expertQuestions");

    return (
        <Edit {...props} mutationMode="pessimistic">
            <SimpleForm>
                <SimpleShowLayout>
                    <TextField source="id" />
                    <TextField source="phpId" />
                    <TextInput source="question" />
                    <TextInput source="answer" />
                    <TextInput source="askedByUserId" />
                    <TextInput source="answeredByAuthorId" />
                    <TextInput source="askedAt" />
                    <TextInput source="answeredAt" />
                    <TextInput source="isAnswered" />
                </SimpleShowLayout>
            </SimpleForm>
        </Edit>
    );
});

export const ExpertQuestionShow = withDefaultLocale(({ defaultLocale, ...props }) => {
    useEscapeToList("/tools/ask-expert/expertQuestions");

    // Get the record using useRecordContext at the top level of the component
    const recordId = useGetRecordId();
    const notify = useNotify(); // Initialize the useNotify hook

    const markAsAnswered = () => {
        console.log("recordId", recordId);

        if (window.confirm("Are you sure you want to mark this question as answered?")) {
            fetch(`${customDataProvider.getApiUrl()}/tools/ask-expert/expertQuestions/${recordId}/markAsAnswered`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("jwt_access_token")}`,
                },
            })
                .then((response) => {
                    console.log("response.status", response.status);
                    if (response.status !== 200) {
                        notify("Failed to mark the question as answered", { type: "error" });
                        throw new Error("Failed to mark the question as answered.");
                    }
                    return response.json();
                })
                .then((data) => {
                    notify("Successfully marked as answered");
                    console.log("Successfully marked as answered", data);
                    // reload page
                    window.location.reload();
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source="question" />
                <TextField source="answer" />
                <TextField source="askedByUserId" />
                <TextField source="answeredByAuthorId" />
                <TextField source="askedAt" />
                <TextField source="answeredAt" />
                <TextField source="isAnswered" />
                <Button variant="contained" color="secondary" onClick={markAsAnswered}>
                    Mark as Answered
                </Button>
            </SimpleShowLayout>
        </Show>
    );
});

export const ExpertQuestionList = withDefaultLocale(({ defaultLocale, ...props }) => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}>
                <TextField source="id" />
                <TextField source="phpId" />
                <TextField source="question" />
                <TextField source="answer" />
                <TextField source="askedByUserId" />
                <TextField source="answeredByAuthorId" />
                <TextField source="askedAt" />
                <TextField source="answeredAt" />
                <TextField source="isAnswered" />
                <EditButton />
            </Datagrid>
        </List>
    );
});
